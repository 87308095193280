@font-face {
    font-family: "Montserrat Thin";
    font-style: normal;
    font-weight: 300;
    src: local("Montserrat"),
        url("./Montserrat-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Regular";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat"),
        url("./Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Medium";
    font-style: normal;
    font-weight: 500;
    src: local("Montserrat"),
        url("./Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat SemiBold";
    font-style: normal;
    font-weight: 600;
    src: local("Montserrat"),
        url("./Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Bold";
    font-style: normal;
    font-weight: 700;
    src: local("Montserrat"),
        url("./Montserrat-Bold.ttf") format("truetype");
}
